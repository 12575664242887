<template>
  <div class="app-container">
    <div class="bg-white main-content-wrapper flex flex-v" style="height: 100%">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" size="mini">
        <el-form-item label="中心节点名称:" prop="deptName">
          <el-col :span="6">
          <el-input v-model="searchForm.deptName" clearable placeholder="请输入中心节点名称"/>
          </el-col>
        </el-form-item>
         <!-- <el-select v-model="searchForm.deptId" label-width="50px" size="mini" clearable placeholder="请选择中心" @change="changeThis">
            <el-option
              v-for="item in centerList"
              :key="item.code"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select> -->
      </el-form>
      <div style="text-align: right;margin-bottom: 10px">
        <el-button size="mini" @click="resetList">重 置</el-button>
        <el-button type="primary" size="mini" @click="getApprovalByCenterList">查 询</el-button>
        <el-button type="primary" size="mini" @click="addRow">添 加</el-button>
      </div>
      <el-form class="flex flex-1 flex-v" size="mini" ref="ruleForm" :model="form">
        <el-table
            class="flex-1 "
            header-cell-class-name="avue-crud"
            size="mini"
            border
            height="666px"
            row-key="id"
            v-loading="tableLoading"
            :data="form.tableData">
          <el-table-column v-for="(item, index) in col"
                           :key="`col_${index}`"
                           :prop="dropCol[index].id+''"
                           :label="item.nodeName">
            <template #default="scope">
              <span v-if="!scope.row.edit">
                <span v-if="item.orderCode === 0">{{ scope.row.deptName }}
                  <span style="color: #909399;float: right">{{ scope.row.headName }}</span>
                </span>
                <span v-else>{{ scope.row[dropCol[index].id] && scope.row[dropCol[index].id].userName }}</span>
              </span>
              <span v-else>
                <el-form-item
                  v-if="item.orderCode === 0"
                  :prop="`tableData.${scope.$index}.deptId`"
                  :rules="[{ required: true, message: '这是一个必填字段', trigger: ['change'] },]">
                  <el-select clearable v-model="scope.row.deptId" size="mini" filterable @change="deptChange">
<!--                     <el-option :label="options.name" :value="options.id" :disabled="options.disabled"-->
<!--                                v-for="options in deptList"></el-option>-->
                    <el-option
                        v-for="options in deptList"
                        :disabled="!options.isChoice"
                        :label="options.name"
                        :value="options.id">
                      <span style="float: left">{{ options.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ options.headName }}</span>
                   </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                    v-else
                    :prop="`tableData.${scope.$index}.${dropCol[index].id+''}.userId`"
                    :rules="[{ required: true, message: '这是一个必填字段', trigger: ['change'] },]">
                  <el-select clearable v-model="scope.row[dropCol[index].id+''].userId" size="mini" filterable>
                    <el-option :label="options.name" :value="options.id" v-for="options in userList">
                       <span style="float: left">{{ options.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ options.phone }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="250px">
            <template #default="scope">
              <el-button type="text" size="mini" @click="saveRow(scope.row, false, scope.$index)" v-if="scope.row.edit">
                保 存
              </el-button>
              <el-button type="text" size="mini" @click="editRow(scope.row,true,scope.$index)" v-if="!scope.row.edit">编
                辑
              </el-button>
              <!--            <el-button type="text" size="mini">启用直线</el-button>-->
              <!--            <el-button type="text" size="mini" style="color: #F56C6C">禁用直线</el-button>-->
              <el-popconfirm
                  style="margin-left: 8px"
                  title="确定删除吗？"
                  confirmButtonType="text"
                  @confirm="removeRow(scope.$index, scope.row)">
                <el-button slot="reference" type="text" size="mini" style="color: #F56C6C">删 除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <div class="pagination pagi-sty">
        <!-- 分页-->
        <Pagination
            :page.sync="tablePage.currentPage"
            :total="tablePage.total"
            :limit.sync="tablePage.pageSize"
            @pagination="paginationChange"
        />
      </div>

    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import service from '@/api/approval'
import Pagination from '@/components/Pagination'
export default {
  name: "index",
  components: {Pagination},
  data() {
    return {
      searchForm: {
        deptName: '',
        deptId: '',
      },
      tableLoading: false,
      deptId: '',
      form: {
        tableData: [],
      },
      col: [],
      dropCol: [],
      userList: [],
      centerList: [],
      deptList: [],
      isEdit: false,
      tablePage: {
        currentPage: 1,
        total: 0,
        pageSize: 10,
      }
    }
  },
  mounted() {
    // this.rowDrop();
    // this.columnDrop();
    this.getTitle();
    this.getTableList();
    this.getUserList();
    this.getApprovaledCenter();
  },
  methods: {
    // 表头数据
    getTitle() {
      service.getTitleList().then(resp => {
        resp.data.unshift({id: 0, nodeCode: "ZXJD", nodeName: "中心节点", orderCode: 0})
        this.col = resp.data;
        this.dropCol = resp.data;
      })
    },
    getTableList() {
      this.tableLoading = true;
      let params = {
        currentPage: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      }
      service.getApprovalList({...params}).then(resp => {
        resp.data.data.forEach(f => {
          f.edit = false;
        })
        this.form.tableData = resp.data.data;
        this.tablePage.total=resp.data.count;
        this.getDeptList()
      }).finally(() => {

      })
    },
    getUserList(id) {
      this.deptId = id;
      service.getUser({deptId: id}).then(resp => {
        this.userList = resp.data;
      })
    },
     changeThis() {
      this.$forceUpdate()
    },
    getApprovalByCenterList(){
      let params = {
        currentPage: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        deptName: this.searchForm.deptName,
      }
      service.getApprovalByCenterList({...params}).then(resp => {
        resp.data.data.forEach(f => {
          f.edit = false;
        })
        this.form.tableData = resp.data.data;
        this.tablePage.total=resp.data.count;
        this.getDeptList()
      }).finally(() => {

      })
    }, 
    resetList(){
      //重置
      this.searchForm.deptName = '',
      this.getTableList();
    },
    getApprovaledCenter(){
        service.getApprovaledCenter().then(resp => {
          this.centerList = resp.data;
        })
    },
    getDeptList() {
      service.getDept({typeCode: 4}).then(resp => {
        let list = [...this.form.tableData];
        list.forEach(f1 => {
          resp.data.forEach(f2 => {
            if (f2.id === f1.deptId) {
              f1.headName = f2.headName;
            }
          })
        })
        this.deptList = resp.data;
        this.form.tableData = list;
      }).finally(() => {
        this.tableLoading = false;
      })
    },
    editRow(row, flag, index) {
      this.getUserList(row.deptId);
      this.form.tableData[index].edit = true;
      this.isEdit = true;
    },
    saveRow(row, flag, index) {
      console.log('row', row,)
      console.log('row', row.nodeId,)
      console.log('col', this.col,)
      this.$refs['ruleForm'].validate(result => {
        if(result){
          let params = {
            deptId: row.deptId,
            userVos: [
              {nodeId: 1, userId: row[1].userId, id: row[1].id ? row[1].id : undefined},
              {nodeId: 2, userId: row[2].userId, id: row[1].id ? row[2].id : undefined},
              {nodeId: 3, userId: row[3].userId, id: row[1].id ? row[3].id : undefined},
              {nodeId: 4, userId: row[4].userId, id: row[1].id ? row[4].id : undefined},
              {nodeId: 5, userId: row[5].userId, id: row[1].id ? row[5].id : undefined},
              {nodeId: 6, userId: row[6].userId, id: row[1].id ? row[6].id : undefined},
              {nodeId: 7, userId: row[7].userId, id: row[1].id ? row[7].id : undefined},
            ]
          };
          this.form.tableData[index].edit = false;
          if (this.isEdit) {
            this.tableLoading = true;
            service.editApproval({...params}).then(resp => {
              if (resp.message === '成功') {
                this.$message.success('编辑成功');
                this.getTableList()
              }
            }).finally(() => {
              this.tableLoading = false;
              this.isEdit = false
            })
          } else {
            this.tableLoading = true;
            service.addApproval({...params}).then(resp => {
              if (resp.message === '成功') {
                this.$message.success('添加成功');
                this.getTableList()
              }
            }).finally(() => {
              this.tableLoading = false;
              this.isEdit = false
            })
          }
        }
      })
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({newIndex, oldIndex}) {
          const currRow = _this.form.tableData.splice(oldIndex, 1)[0]
          _this.form.tableData.splice(newIndex, 0, currRow)
        }
      })
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: evt => {
          const oldItem = this.dropCol[evt.oldIndex]
          this.dropCol.splice(evt.oldIndex, 1)
          this.dropCol.splice(evt.newIndex, 0, oldItem)
        }
      });
    },
    addRow() {
      let params = {};
      this.col.forEach((f, index) => {
        params[f.id] = {userId: ''};
      })
      this.form.tableData.push({
        edit: true,
        // ...params,
        deptId: '',
        1: {userId: ''},
        2: {userId: ''},
        3: {userId: ''},
        4: {userId: ''},
        5: {userId: ''},
        6: {userId: ''},
        7: {userId: ''},
      })
    },
    removeRow(index, row) {
      this.form.tableData.splice(index, 1);
      service.removeDept({deptId: row.deptId}).then(resp => {
        if (resp.message === '成功') {
          this.$message.success('删除成功');
          this.getTableList();
        }
      });
    },
    deptChange(val) {
      this.getUserList(val);
      // this.deptList = this.deptList.map(m => {
      //   if (m.id === val) {
      //     m.disabled = true;
      //   }
      //   return m;
      // });
    },

    // 分页切换
    paginationChange({ page = 1, limit = 10 }) {
      this.tablePage.currentPage = page
      this.tablePage.pageSize = limit
      this.getTableList();
    },
  }
}
</script>

<style scoped>
.app-container {
  height: 100%;
  background-color: #f6f6f6;
}
</style>
