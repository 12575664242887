import {getRequest,postRequest,putRequest,deleteRequest} from "@/utils/request_utils"
const base = '/api-base'
export default {
  getTitleList: (params) => {
   return  getRequest(`${base}/approval/getHeads`, {params});
  },
  getApprovalList: (params) => {
   return  getRequest(`${base}/approval/getPageList`, {params});
  },
  //启用禁用直线
  stopAndUseApproval: (data) => {
   return  postRequest(`${base}/approval/isLine`, {data});
  },
  //更新审批人列表
  editApproval: (data) => {
   return  postRequest(`${base}/approval/updateUsers`, {data})
  },
  addApproval: (data) => {
    return postRequest(`${base}/approval/insertUsers`, {data})
  },
  getUser:(params) => {
    return getRequest(`${base}/loginUser/list`, {params})
  },
  getDept: (data) => {
    return postRequest(`${base}/dept/listByTypeCode`, {data})
  },
  removeDept: (data)=> {
    return postRequest(`${base}/approval/delete`, {data})
  },
  getApprovalByCenterList: (data) => {
    return postRequest(`${base}/approval/getApprovalByDeptid`, {data});
  },
  getApprovaledCenter: (params) => {
    return getRequest(`${base}/approval/getApprovalCentetr`, {params});
  },



  // treeMethod(data){
  //   data.forEach((f,index) => {
  //     if(index+2 > data.length){
  //       return
  //     }
  //     f.children = data[index+1];
  //     this.treeMethod(f)
  //   })
  //   return data;
  // }

}
